/* AnswerBalloon.css */
.answerBalloon {
    position: relative;
    background-color: #4f6174;
    color: #fff;
    padding: 10px;
    border-radius: 10px;
    max-width: 70%;
    margin: 10px;
    align-self: flex-start;
    flex-direction: column;
    display: flex;
  }
  
  .answerBalloonContent {
    font-size: 16px;
  }
  