/* Define a CSS ID selector named 'checklist' */
#welcomeMessages {
    list-style: none;
    padding-left: 0;
  }
  
  #welcomeMessages li {
    position: relative;
    padding-left: 30px;
  }
  
  #welcomeMessages li::before {
    content: "\2713";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    color: #009900;
    margin-right: 5px;
  }
  