/* AskBalloon.css */
.askBalloon {
    position: relative;
    background-color: #547eac;
    color: #fff;
    padding: 10px;
    border-radius: 10px;
    max-width: 70%;
    margin: 10px;
    align-self: flex-end;
    flex-direction: column;
    display: flex;
  }
  
  .askBalloonContent {
    font-size: 1em;
  }
  