body {
  /*height: 100%;*/
  min-height: 100vh;
  min-height: fill-available;
  min-height: -webkit-fill-available;
  padding: 0;
  margin: 0;
  overflow: hidden; /* make browser scrollbar disappear */
}
html {
  height: 100vh;
  height: fill-available;
  height: -webkit-fill-available;
}

.guest {
  background-color: #f5f5f5;
  font-family: "Your Chosen Font", Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers divs horizontally */
  width: 100%; /* Takes full width of the parent container */
  height: 100dvh;
  overflow-y: auto;
}
